@import "styles/variables";
@import "styles/mixins/breakpoints";

.select {
  &__placeholder {
    font-size: var(--select-placeholder-font-size);
    line-height: var(--select-placeholder-line-height);
    color: var(--select-default-placeholder-color) !important;
    margin-left: 20px !important;
  }

  &__control {
    min-height: var(--select-min-height) !important;
    padding: 0 !important;

    &--is-disabled {
      background-color: var(--select-disabled-background-color) !important;
    }
  }

  &__multi-value {
    margin: 4px !important;
    border-radius: var(--select-multi-value-border-radius) !important;

    color: white;

    background-color: var(--palettes-brand-beauty-dark-gray) !important;
  }

  &__multi-value__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 6px !important;

    cursor: pointer;

    transition: 0.2s;

    &:hover {
      color: white !important;

      background-color: var(--palettes-gray-90) !important;
    }

    &:active {
      color: white !important;

      background-color: #000 !important;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__multi-value__label {
    padding: 7px 8px !important;

    color: white !important;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
  }
}
