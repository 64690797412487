.ReactModal__Body {

  &--open {
    overflow: hidden;
  }

  .modal,
  .modal-wrapper {
    transition: 0.2s;
    opacity: 0;
    transform: translate(720px, 0);
  }
}

//.ReactModal__Overlay {
//  top: 0 !important;
//}

//.ReactModal__Content {
//  transform: translate(720px, 0);
//  transition: 0.2s;
//
//  @media (min-width: 768px) {
//    transform: translate(0, 0);
//    opacity: 0;
//    top: 0 !important;
//  }
//
//  &--after-open {
//    opacity: 1;
//    transform: translate(0, 0);
//    outline: none;
//
//    @media (min-width: 768px) {
//      transform: translate(0, 0);
//      opacity: 1;
//    }
//
//    .modal,
//    .modal-wrapper {
//      opacity: 1;
//      transform: translate(0);
//    }
//  }
//}

.ReactModal__Overlay {
  opacity: 0;
  transition: 0.2s;

  &--after-open {
    opacity: 1;
  }
}
