@import "root";
@import "variables";

@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/spacers";

@import "components/normalize";
@import "components/grid";
@import "components/spacers";
@import "components/select";
@import "components/carousel";
@import "components/datepicker";
@import "components/modal";
@import "fonts/fonts.css";

.shadowLevel1 {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.shadowLevel2 {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.04);
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  overflow: hidden;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.text-center {
  text-align: center;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-gradient-purple {
  background: var(--purple-gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-marine {
  background: var(--marine-gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-blue {
  background: var(--blue-gradient-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-light-blue {
  background: var(--blue-gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-lilac {
  background: var(--lilac-gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white !important;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 174, 255, 0.04) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0.03) 100%
  );
}

input[data-com-onepassword-filled="light"],
input[data-com-onepassword-filled="dark"],
select[data-com-onepassword-filled="dark"],
textarea[data-com-onepassword-filled="dark"] {
  background-color: transparent !important;
}

.roundCorners {
  border-radius: 80px;

  @include media-breakpoint-only(xl) {
    border-radius: 72px;
  }

  @include media-breakpoint-only(l) {
    border-radius: 60px;
  }

  @include media-breakpoint-only(m) {
    border-radius: 56px;
  }

  @include media-breakpoint-only(s) {
    border-radius: 40px;
  }

  @include media-breakpoint-only(xs) {
    border-radius: 28px;
  }

  @include media-breakpoint-down(xs) {
    border-radius: 24px;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.designDreamFixer {
  content: "";
  display: block;
  width: 100%;
  height: 200px;
  position: absolute;
  z-index: 0;
  background-color: var(--light-marine-100);
  background-image: url("/componentsImages/Hero/marine/background.jpg");
  background-size: cover;
  transform: scaleY(-1);
  background-position-y: bottom;
}
